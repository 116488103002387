// Entry point for the build script in your package.json

// Rails ActionCable channels
// import 'channels'

// Popper is needed for Bootstrap
import createPopper from '@popperjs/core'
import * as bootstrap from 'bootstrap'

// Enable Turbo Rails
import "@hotwired/turbo-rails"
Turbo.session.drive = false

// Enable ClipboardJS
import ClipboardJS from 'clipboard'

// Enable jQuery
import jquery from 'jquery'
window.$ = jquery

import ClickableRows from './clickable_row.js'
new ClickableRows()

import ContactForm from './contact_form.js'
new ContactForm()

import TransactionForm from './transaction_form.js'
new TransactionForm()

import DatePickers from './date_pickers.js'
new DatePickers()

document.addEventListener('turbo:load', () => {
  // Auto close dismissible alerts
  setTimeout(() => {
    $('.alert-dismissible').fadeOut(1000)
  }, 1000)

  // Initialize all Bootstrap tooltips
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipTriggerEl) => {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  // ClipboardJS
  new ClipboardJS('.copy-link')

  // Focus on the search field on the contacts index page
  if (document.body.matches('.contacts.index')) {
    document.getElementById('q_last_name_or_first_name_or_business_name_or_email_cont').focus()
  }

  // Focus on the search field on the transactions index page
  if (document.body.matches('.transactions.index')) {
    document.getElementById('q_contact_last_name_or_contact_first_name_or_contact_business_name_or_contact_email_or_amount_as_char_cont').focus()
  }
})
